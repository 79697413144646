<template>
  <section class="section" style="padding: 3.5rem">
    <div class="container has-text-centered">
      <h1 class="title is-size-1 is-size-3-mobile has-text-secondary">
        Oops...
      </h1>
      <img
        src="/assets/NotFound.png"
        alt="Página não encontrada"
        style="height: 160px;"
      />
      <h3 class="title is-size-4 is-size-5-mobile">
        Parece que a página que você está tentando acessar não existe
      </h3>
      <div class="grow-hover">
        <router-link tag="a" to="/" class="title is-size-5 has-text-secondary">
          Voltar para página inicial
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
